import React, { useEffect, useState } from "react";
import { Wrapper, HiddenContainer, Text } from "./TopHeading.styles";

const TopHeading = ({ heading, bgImage, bgPositionY }) => {

    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
       

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollY]);

    const wrapperStyle = {
        backgroundImage: `url(${bgImage})`,
        backgroundPositionY: `calc(${bgPositionY } - ${scrollY / 20}px)`,
        backgroundPositionX: `center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`
      };

    return (
        <>
            <HiddenContainer />
            <Wrapper style={wrapperStyle}>

                <Text>
                    {heading}
                </Text>
            </Wrapper>
        </>

    )
};

export default TopHeading;