import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--bgColor);
    padding: 100px 20px;

    h2 {
        color: var(--mainColor);
        margin-bottom: 20px;
        font-size: x-large;
    }

    p {
        color: #393939;
        line-height: 30px;
    }
`;

