import React from "react";
import { useNavigate } from 'react-router-dom';
import { Wrapper, MobileWrapper } from "./Header.styles";
import LanguageSelector from "../LanguageSelector";

const Header = () => {
    const navigate = useNavigate();
    return (
        <>
            <Wrapper>
                <p onClick={ () => navigate('/') }>Main</p>
                <p onClick={ () => navigate('/manufacturers') }>Manufacturers</p>
                <p onClick={ () => navigate('/stocklist') }>Stocklist</p>
                <p onClick={ () => navigate('/en-premeur') }>En premeur</p>
                <p onClick={ () => navigate('/fine-wine-and-spirit') }>Fine Wine and Spirit</p>
                <p onClick={ () => navigate('/investments') }>Investments</p>
                <p onClick={ () => navigate('/Contacts') }>Contacts</p>
                <LanguageSelector />
            </Wrapper>
            <MobileWrapper>
                <div id="menuToggle">
                    <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul id="menu">
                        <li onClick={ () => navigate('/') }>Main</li>
                        <li onClick={ () => navigate('/manufacturers') }>Manufacturers</li>
                        <li onClick={ () => navigate('/stocklist') }>Stocklist</li>
                        <li onClick={ () => navigate('/en-premeur') }>En premeur</li>
                        <li onClick={ () => navigate('/fine-wine-and-spirit') }>Fine Wine and Spirit</li>
                        <li onClick={ () => navigate('/investments') }>Investments</li>
                        <li onClick={ () => navigate('/Contacts') }>Contacts</li>
                        <LanguageSelector />
                    </ul>
                    
                </div>
            </MobileWrapper>
        </>
        
    )
};

export default Header;