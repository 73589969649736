import styled from "styled-components";

export const Select = styled.select`
    padding: 6px;
    border-radius: 8px;
    background-color: var(--bgColor);
    position: absolute;
    right: 16px;

    @media (max-width: 1100px) {
        top: 62px;
    }
`;