import React, { useState } from "react";
import { Wrapper, ModalOverlay, ModalContainer, ModalHeader, ModalContent } from "./Modal.styles";
import CloseIcon from "../../assets/images/close.svg";

const Modal = ({ isOpen, onClose, header, children }) => {
    const [isModalOpen, setModalOpen] = useState(isOpen);

    const closeModal = () => {
        setModalOpen(false);
        onClose && onClose();
    };

    React.useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    if (!isModalOpen) {
        return null;
    }

    return (
        <Wrapper>
            <ModalOverlay>
                <ModalContainer>
                    <ModalHeader>
                        <h1>{header}</h1>
                        <img onClick={closeModal} src={CloseIcon} alt="Close" />
                    </ModalHeader>
                    <ModalContent>
                        {children}
                    </ModalContent>
                </ModalContainer>
            </ModalOverlay>
        </Wrapper>
    )
};

export default Modal;