import React from "react";
import { Wrapper, TextContainer, MainText, SecondText } from "./VideoBg.styles";
import bg from "../../assets/videos/bg.mp4";
import { useTranslation } from "react-i18next";

const VideoBg = () => {
    const { t } = useTranslation("global");

    return (
        <Wrapper>
            <video autoPlay loop muted>
                <source src={bg} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <TextContainer>
                <MainText>WineCraft</MainText>
                <SecondText>{t("main.some_text")}</SecondText>
            </TextContainer>
        </Wrapper>
    )
};

export default VideoBg;