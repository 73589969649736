import React from "react";
import { Wrapper } from "./Divider.styles";

const Divider = ({text}) => {

    return (
        <Wrapper>
            <p>{text}</p>
        </Wrapper>
    )
};

export default Divider;