import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 26px;
    width: calc(100% - 100px);
    background-color: var(--bgColor);
    padding: 100px 50px;

    @media (max-width: 580px) {
        width: 100%;
        padding: 100px 0;
    }
`;

export const PartnerContainer = styled.a`
    width: 450px;
    height: 300px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 4px gray;

    &:hover {
        box-shadow: 0px 0px 20px gray;
        cursor: pointer;
    }
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }

    @media (max-width: 580px) {
        width: 300px;
        height: 200px;
    }
`;