import React, {useEffect} from "react";
import { Wrapper, LoginContainer } from "./Login.styles";
import Input from "../Input";
import Button from "../Button";

const Login = () => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isLoggedIn, setIsLoggedIn] = React.useState(false)
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    function csrfSafeMethod(method) {
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }


    function login(){
        const csrfToken = getCookie('XSRF-TOKEN');
        let headers = {};

        if (!csrfSafeMethod('POST') && csrfToken) {
            headers['X-CSRF-TOKEN'] = csrfToken; // Add CSRF token to the request header
        }

        fetch("http://localhost:8080/api/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("HTTP response not ok");
                }
            })
            .then(json => {
                localStorage.setItem("loginToken", json.token)
                window.location.reload()
            })
            .catch(error => {
                alert("Error: " + error.message);
            });
    }

    useEffect(() => {
        let headers = {};
        setIsLoggedIn(false)
        console.log(localStorage.getItem('loginToken'))
        fetch("http://localhost:8080/api/login/check", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            body: JSON.stringify({
                token: localStorage.getItem('loginToken')
            }),
        })
            .then(response => {
                if (response.ok) {
                    setIsLoggedIn(true)
                    return response.json();
                }
            })
            .then(json => {
                if (json === false){
                    setIsLoggedIn(false)
                }
            })
            .catch(error => {
                alert("Error: " + error.message);
            });
    }, []);

    return (
        <Wrapper>
            {isLoggedIn
                ?
                <p>User is logged in</p>
                :
                <LoginContainer>
                    <p>Admin Login</p>

                    <Input type="text"
                           value={username}
                           onChange={(event) => {setUsername(event.target.value)}}
                           placeholder="Username"
                           required />

                    <Input type="password"
                           value={password}
                           onChange={(event) => {setPassword(event.target.value)}}
                           placeholder="Password"
                           required />

                    <Button type="submit" onClick={login} label={"Login"} />
                </LoginContainer>
            }

        </Wrapper>
    )
};

export default Login;