import React, {useRef} from "react";
import emailjs from '@emailjs/browser';
import {Wrapper, InfoContainer, ContactUsForm} from "./Footer.styles";
import {useTranslation} from "react-i18next";

const Footer = ({
                    registerCode,
                    kmkrNr
                }) => {
    const form = useRef();
    const {t} = useTranslation("global");
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("send");

        emailjs
            .sendForm('service_upq1oln', 'template_nja0lsl', form.current, {
                publicKey: '0WMzPt08JDiZtFjqP',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <Wrapper>
            <InfoContainer>
                <p><strong>WineCraft OÜ</strong></p>
                <p>Kadastiku 57N, Narva, Ida-Virumaa, 21004</p>
                <p>winecraft.est@gmail.com</p>
                <p><strong>{registerCode}:</strong> 14160026</p>
                <p><strong>{kmkrNr}:</strong> EE101971766</p>
            </InfoContainer>
            <ContactUsForm>
                <form id="contactUsForm" ref={form} onSubmit={sendEmail}>
                    <p>Contact us</p>
                    <input type="email" name="user_email" placeholder="Your Email"/>
                    <input type="text" name="user_name" placeholder="Your Name"/>
                    <textarea name="message" placeholder="Your Message"/>
                    <button type="submit">Send</button>
                </form>

            </ContactUsForm>
        </Wrapper>
    )
};

export default Footer;