import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: rgb(45 45 45);

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px 16px 50px 200px;

    h2 {
        color: white;
        margin-bottom: 20px;
        font-size: x-large;
    }

    p {
        color: white;
        line-height: 30px;
    }

    @media (max-width: 1200px) {
        margin: 50px 100px;
    }

    @media (max-width: 900px) {
        margin: 50px 32px;
    }

    @media (max-width: 700px) {
        align-items: center;
    }
`;

export const ContactUsForm = styled.div`
    display: flex;
    justify-content: center;
    margin: 50px 200px 50px 16px;

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 350px;

        p {
            color: white;
            text-align: center;
            margin-bottom: 15px;
            font-size: x-large;
        }

        input {
            height: 20px;
            margin-bottom: 10px;
            border-radius: 0.375rem;
            border-width: 1px;
            border-color: #cbcdd166;
            --tw-bg-opacity: 1;
            background-color: rgb(255 255 255 / var(--tw-bg-opacity));
            padding: 0.5rem 0.75rem;
            --tw-text-opacity: 1;
            color: rgb(16 20 25 / var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
            --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);

            &:focus {
                outline: none !important;
                border:1px solid var(--mainColor);
            }
        }

        textarea {
            height: 100px;
            margin-bottom: 10px;
            border-radius: 0.375rem;
            border-width: 1px;
            border-color: #cbcdd166;
            --tw-bg-opacity: 1;
            background-color: rgb(255 255 255 / var(--tw-bg-opacity));
            padding: 0.5rem 0.75rem;
            --tw-text-opacity: 1;
            color: rgb(16 20 25 / var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
            --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);

            &:focus {
                outline: none !important;
                border:1px solid var(--mainColor);
            }
        }

        button {
            background-color: var(--mainColor);
            width: 100%;
            border: none;
            margin-top: 10px;
            height: 35px;
            color: white;
            cursor: pointer;
            border-radius: 0.375rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #69835c;
            }
        }
    }

    @media (max-width: 1200px) {
        margin: 50px 100px;
    }

    @media (max-width: 900px) {
        margin: 50px 32px;
    }

    @media (max-width: 412px) {
        width: calc(100% - 32px);

        form {
            width: 100%;
        }
    }
`;