import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bgColor);
    padding: 100px 20px;
    gap: 80px;
    flex-wrap: wrap;
`;

export const PersonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ImageContainer = styled.div`
    width: 310px;
    height: 460px;
    background-color: white;
    position: relative;
    padding: 6px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const Title = styled.p`
    color: var(--mainColor);
    font-size: 24px;
    margin-top: 16px;
`;

export const Name = styled.p`
    color: #393939;
`;