import React from "react";
import { Wrapper, PersonContainer, ImageContainer, Title, Name } from "./Team.styles";
import TeamMemberImage from "../../assets/images/team-member.webp";

const Team = () => {

    return (
        <Wrapper>
            <PersonContainer>
                <ImageContainer>
                    <img src={TeamMemberImage} alt="Team member" />
                </ImageContainer>
                <Title>Title</Title>
                <Name>Name Surname</Name>
            </PersonContainer>

            <PersonContainer>
                <ImageContainer>
                    <img src={TeamMemberImage} alt="Team member" />
                </ImageContainer>
                <Title>Title</Title>
                <Name>Name Surname</Name>
            </PersonContainer>
        </Wrapper>
    )
};

export default Team;