import React from "react";
import { Wrapper, ImgContainer, TextContainer, ButtonWrapper } from "./SideImageAndText.styles";

const SideImageAndText = ({ img, text, alt, showContactBtn }) => {

    return (
        <Wrapper>
            <ImgContainer>
                <img src={img} alt={alt} />
            </ImgContainer>
            <TextContainer>
                <p>{text}</p>
                {showContactBtn &&
                    < ButtonWrapper >
                        <a href="#contactUsForm">Contact us</a>
                    </ButtonWrapper>
                }
            </TextContainer>
        </Wrapper >
    )
};

export default SideImageAndText;