import React from 'react';
import { GlobalStyle } from './GlobalStyle';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Pages
import HomePage from './pages/HomePage';
import AdminLoginPage from './pages/AdminLoginPage';
import AdminPanelPage from './pages/AdminPanelPage';
import StocklistPage from './pages/StocklistPage';
import Manufacturers from './pages/Manufacturers';
import Investments from './pages/Investments';
import Contacts from './pages/Contacts';
import EnPremeur from './pages/EnPremeur';
import FineWinesAndSpirits from './pages/FineWinesAndSpirits';

function App() {
  return (
    <Router>
      <GlobalStyle />
      
      <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/admin' element={<AdminLoginPage />} />
          <Route path='/admin-panel' element={<AdminPanelPage />} />
          <Route path='/stocklist' element={<StocklistPage />} />

          <Route path='/manufacturers' element={<Manufacturers />} />
          <Route path='/investments' element={<Investments />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/en-premeur' element={<EnPremeur />} />
          <Route path='/fine-wine-and-spirit' element={<FineWinesAndSpirits />} />
      </Routes>
    </Router>
  );
}

export default App;
