import React from "react";
import { ButtonComponent } from "./Button.styles";

const Button = ({ type, label, onSubmit, onClick }) => {
    return (
        <ButtonComponent 
            type={type}
            onSubmit={onSubmit}
            onClick={onClick}
        >
            {label}
        </ButtonComponent>
    );
};

export default Button;