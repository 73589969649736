import React from "react";
import { Wrapper, PartnerContainer } from "./ListOfManufacturers.styles";
import Partner1 from "../../assets/images/manufacturers/partner1.jpg";
import Partner2 from "../../assets/images/manufacturers/partner2.webp";
import Partner3 from "../../assets/images/manufacturers/partner3.webp";
import Partner4 from "../../assets/images/manufacturers/partner4.webp";
import Partner5 from "../../assets/images/manufacturers/partner5.jpg";
import Partner6 from "../../assets/images/manufacturers/partner6.jpg";
import Partner7 from "../../assets/images/manufacturers/partner7.png";
import Partner8 from "../../assets/images/manufacturers/partner8.png";
import Partner9 from "../../assets/images/manufacturers/partner9.jpg";

const ListOfManufacturers = () => {

    return (
        <Wrapper>
            <PartnerContainer href="https://frerejeanfreres.com/" target="_blank">
                <img src={Partner1} alt="FREREJEAN FRÈRES" />
            </PartnerContainer>
            <PartnerContainer href="https://www.champagne-collery.com/en/presentation/" target="_blank">
                <img src={Partner2} alt="COLLERY" />
            </PartnerContainer>
            <PartnerContainer href="https://www.champagne-lacourte-godbillon.com/en/" target="_blank">
                <img src={Partner3} alt="LACOURTE GODBILLON" />
            </PartnerContainer>
            <PartnerContainer href="http://champagne-hugues-godme.com/" target="_blank">
                <img src={Partner4} alt="HUGUES GODMÉ" />
            </PartnerContainer>
            <PartnerContainer href="https://www.champagne-william-saintot.fr/en" target="_blank">
                <img src={Partner5} alt="WILLIAM SAINTOT" />
            </PartnerContainer>
            <PartnerContainer href="https://www.champagnesolemme.fr/" target="_blank">
                <img src={Partner6} alt="SOLEMME" />
            </PartnerContainer>
            <PartnerContainer href="https://www.champagne-simon-rion.fr/" target="_blank">
                <img src={Partner7} alt="SIMON RION" />
            </PartnerContainer>
            <PartnerContainer href="https://www.champagne-coulon.com/" target="_blank">
                <img src={Partner8} alt="ROGER COULON" />
            </PartnerContainer>
            <PartnerContainer href="https://www.champagne-remi-leroy.fr/#TOP" target="_blank">
                <img src={Partner9} alt="RÉMI LEROY" />
            </PartnerContainer>
        </Wrapper>
    )
};

export default ListOfManufacturers;