import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: -1;
`;

export const HiddenContainer = styled.div`
    height: 500px;
    width: 100%;
`;

export const Text = styled.p`
    color: white;
    font-size: 63px;
    font-family: 'PlayfairDisplay';
    font-style: italic;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px black;
    text-align: center;
    line-height: 64px;

    @media (max-width: 550px) {
        font-size: 40px;
        line-height: 46px;
    }
`;