import styled from "styled-components";

export const Wrapper = styled.div`
    
`;

export const ModalOverlay = styled.div`
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
`;

export const ModalContainer = styled.div`
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 15px;
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;

    img {
        cursor: pointer;
    }
`;

export const ModalContent = styled.div`
    margin: 30px 0;
`;