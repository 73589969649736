import React from "react";
import { Wrapper } from "./CenterText.styles";

const CenterText = ({text}) => {

    return (
        <Wrapper>
            <p>{text}</p>
        </Wrapper>
    )
};

export default CenterText;