import styled from "styled-components";
import AdminBg from "../../assets/images/admin-bg.jpg";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 300px);
    background: url('${AdminBg}') center/cover no-repeat;
`;

export const LoginContainer = styled.div`
    width: 100%;
    max-width: 400px;
    margin-top: 100px;
    padding: 30px 30px 60px 30px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 1);

    p {
        margin-bottom: 30px;
        text-align: center;
        font-size: x-large;
    }
`;