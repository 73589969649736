import styled from "styled-components";

export const InputField = styled.input`
    height: 20px;
    width: calc(100% - 26px);
    margin-bottom: 10px;
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: #cbcdd166;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 0.5rem 0.75rem;
    --tw-text-opacity: 1;
    color: rgb(16 20 25 / var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);

    &:focus {
        outline: none !important;
        border:1px solid var(--mainColor);
    }
`;