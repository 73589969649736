import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;

    @media (max-width: 1170px) {
        flex-direction: column;
    }
`;

export const ImgContainer = styled.div`
    display: flex;

    img {
        width: 900px;
    }

    @media (max-width: 1450px) {
        img {
            width: 600px;
        }
    }

    @media (max-width: 1170px) {
        img {
            width: 100%;
        }
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--bgColor);

    p {
        width: 500px;
        color: var(--mainColor);
    }

    @media (max-width: 1170px) {
        padding: 64px 0;
    }

    @media (max-width: 550px) {
        p {
            width: calc(100% - 32px);
            padding: 0 16px;
            text-align: center;
        }
    }
`;

export const ButtonWrapper = styled.div`
    width: 500px;
    margin-top: 16px;

    a {
        background-color: var(--mainColor);
        width: 100%;
        border: none;
        margin-top: 10px;
        height: 35px;
        color: white;
        cursor: pointer;
        border-radius: 0.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:hover {
            background-color: #69835c;
        }
    }

    @media (max-width: 550px) {
        width: calc(100% - 32px);
        padding: 0 16px;
        text-align: center;
    }
`;