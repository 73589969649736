import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100vh;
    width: auto;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100%);
    }
`;

export const TextContainer = styled.div`
    position: absolute;
    top: 250px;
    left: 200px;

    @media (max-width: 800px) {
        left: auto;
        width: 100%;
        text-align: center;
    }
`;

export const MainText = styled.p`
    color: white;
    font-size: 100px;
    font-weight: 600;
    font-family: arial black,arial-w01-black,arial-w02-black,arial-w10 black,sans-serif;
    letter-spacing: 0.05em;
    line-height: normal;
    text-shadow: 0px 0px 20px black;

    @media (max-width: 1100px) {
        font-size: 70px;
    }

    @media (max-width: 550px) {
        font-size: 45px;
    }
`;

export const SecondText = styled.p`
    font-size: 30px;
    font-family: arial black,arial-w01-black,arial-w02-black,arial-w10 black,sans-serif;
    color: var(--mainColor);
    text-shadow: 0px 0px 10px black;

    @media (max-width: 1100px) {
        font-size: 24px;
    }

    @media (max-width: 550px) {
        font-size: 16px;
    }
`;