import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: auto;
    background-color: var(--mainColor);

    p {
        color: white;
        font-size: 55px;
        font-family: 'PlayfairDisplay';
        font-style: italic;
        font-weight: 600;
        letter-spacing: 1px;
        text-shadow: 0.4px 0.4px 0.6px black;
        text-align: center;
        line-height: 52px;
    }
    
    @media (max-width: 550px) {
        p {
            font-size: 34px;
        }
    }
`;