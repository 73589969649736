import React from 'react';

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';
import Table from '../components/Table';

const AdminPanelPage = () => {

    return (
        <>
            <Header />
            <Table showAdminPanel={false} />
            <Footer />
        </>
    );

}

export default AdminPanelPage;