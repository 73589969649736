import React from 'react';
import { useTranslation } from "react-i18next";

// Components
import Header from '../components/Header';
import VideoBg from '../components/VideoBg';
import Divider from '../components/Divider';
import SideImageAndText from '../components/SideImageAndText';
import Footer from '../components/Footer';

import SideImage from '../assets/images/about-us.jpg'

const HomePage = () => {
    const { t } = useTranslation("global");

    return (
        <>
            <Header />
            <VideoBg />
            <Divider text={t("main.heading")} />
            <SideImageAndText img={SideImage} showContactBtn={false} alt={t("main.heading")} text={t("main.text")} />
            <Footer registerCode={t("contacts.registerCode")}
                    kmkrNr={t("contacts.kmkrNr")}
            />
        </>
    );

}

export default HomePage;