import React from "react";
import { InputField } from "./Input.styles";

const Input = ({ type, value, placeholder, onChange, required, style  }) => {

    return (
        <InputField
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            style={style}
        />
    )
};

export default Input;