import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    width: 100%;
    background-color: var(--bgColor);

    p {
        width: 700px;
        text-align: center;
    }

    @media (max-width: 732px) {
        p {
            width: 100%;
            margin: 0 16px;
        }
    }
`;