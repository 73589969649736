import React from "react";
import {Wrapper} from "./ContactInfo.styles";
import {useTranslation} from "react-i18next";

const ContactInfo = ({
                         representativeStore,
                         wineLibrary,
                         warehouse,
                         registerCode,
                         kmkrNr
                     }) => {


    return (
        <Wrapper>
            <div style={{marginBottom: "1rem"}}>
                <p><strong>{representativeStore}:</strong> Mustamäe tee 4, Tallinn, Eesti</p>
                <p><strong>{wineLibrary}:</strong> Puškini 9-17, Narva</p>
                <p><strong>{warehouse}:</strong> Kadastiku 57N, Narva</p>
            </div>

            <p><strong>WineCraft OÜ</strong></p>
            <p>Kadastiku 57N, Narva, Ida-Virumaa, 21004</p>
            <p>winecraft.est@gmail.com</p>
            <p><strong>{registerCode}:</strong> 14160026</p>
            <p><strong>{kmkrNr}:</strong> EE101971766</p>
        </Wrapper>
    )
};

export default ContactInfo;