import styled from "styled-components";
import AdminBg from "../../assets/images/admin-bg.jpg";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: url('${AdminBg}') center/cover no-repeat;
`;

export const AdminPanelContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 200px;
    margin-bottom: 10px;
`;

export const AdminPanel = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;

export const Button = styled.button`
    border: none;
    height: 38px;
    cursor: pointer;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;

    &:hover {
        background-color: #69835c;
        color: #fff;
    }
`;

export const TableContainerWrapper = styled.div`
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    width: calc(100% - 160px);
    margin-bottom: 100px;
`;

export const TableContainer = styled.table`
    border-collapse: collapse;
    border-radius: 10px;
    // overflow: hidden;
    font-size: 15px;
    color: gray;
    line-height: 1.2;
    width: 100%;

    td, th {
        padding: 8px;
    }

    tr {
        background-color: #fff;
    }
    
    tr:nth-child(even){
        background-color: #f2f2f2;
    }
    
    tr:hover {
        background-color: #ddd;
    }

    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: var(--mainColor);
        color: white;
    }

`;