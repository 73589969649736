import styled from "styled-components";

export const ButtonComponent = styled.div`
    background-color: var(--mainColor);
    width: 100%;
    border: none;
    margin-top: 10px;
    height: 35px;
    color: white;
    cursor: pointer;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #69835c;
    }
`;