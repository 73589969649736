import React, { useEffect } from "react";
import { Select } from "./LanguageSelector.styles";
import { useTranslation } from 'react-i18next';

const Input = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        console.log(storedLanguage)
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    };

    return (
        <Select value={i18n.language} onChange={(e) => changeLanguage(e.target.value)}>
            <option value="en">Eng</option>
            <option value="est">Est</option>
            <option value="rus">Rus</option>
        </Select>
        
    )
};

export default Input;